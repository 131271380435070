import React, { useState } from "react";
import styles from './AccordionItem.module.scss';
import Text from "../../../../shared/ui/Text/Text";
import { Stack } from "../../../../shared/ui/Stack/Stack";

export const AccordionItem = ({ title, content, defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  }

  return (
    <div className={styles.accordionItem}>
      <Stack
        className={styles.titleContainer}
        justify='justifyBetween'
        align='alignCenter'
        onClick={handleIsOpen}
        aria-label="Accordion Item Button"
        tabIndex="0"
        role='button'
        onKeyDown={handleIsOpen}
      >
        <Stack align='alignCenter'>
          <Text
            className={styles.title}
            size="lg"
          >
            {title}
          </Text>
        </Stack>
        {isOpen ? (
          <svg 
            width="23" 
            height="2" 
            viewBox="0 0 16 2" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15.9839 0.2V1.76H0.943926V0.2H15.9839Z" fill="black"/>
          </svg>
        ):(
          <svg 
            width="21" 
            height="22" 
            viewBox="0 0 21 22" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20.541 11.76H11.181V21.2H9.461V11.76H0.141001V10.24H9.461V0.799998H11.181V10.24H20.541V11.76Z" fill="black"/>
          </svg>
        )}
      </Stack>

      {isOpen && (
          <Text className={styles.content}>
            {content}
          </Text>
      )}
    </div>
  );
};
