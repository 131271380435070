import eCom from "../../../shared/assets/icons/eCommerceDevelopment.svg";
import mobRes from "../../../shared/assets/icons/mobileResponsiveness.svg";
import cms from "../../../shared/assets/icons/scm.svg";
import seo from "../../../shared/assets/icons/seo.svg";
import webDes from "../../../shared/assets/icons/webDesign.svg";
import webDev from "../../../shared/assets/icons/webDevelopment.svg";

export const data = {
  title:"Our Services",
  subtitle: "What are you looking for", 
}
export const dataService = [
  {
    icon: eCom,
    title: "Web development",
    content: "Building websites using various programming languages and frameworks (e.g., HTML, CSS, JavaScript, React, Node, Python, Flutter, etc.).",
  },
  {
    icon: webDev,
    title: "Mobile responsiveness",
    content: "Ensuring that websites are optimized for viewing on various devices, including desktops, tablets, and smartphones.",
  },
  {
    icon: cms,
    title: "E-commerce development",
    content: "Building online stores with features like shopping carts, payment gateways, and inventory management systems.",
  },
  {
    icon: seo,
    title: "Search engine optimization (SEO)",
    content: "Implementing technical SEO, accessibility, and best practices to improve a website's visibility and ranking in search engine results pages (SERPs)",
  },
  {
    icon: webDes,
    title: "Web design",
    content: "Creating visually appealing and user-friendly website layouts and interfaces.",
  },
  {
    icon: mobRes,
    title: "Mobile App Development",
    content: 'Developing mobile applications for iOS and Android platforms using technologies like Flutter, ensuring functionality and performance across different devices.'
  },
];

