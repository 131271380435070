import React, { useState } from 'react';
import styles from './Projects.module.scss';
import { Stack } from '../../../../shared/ui/Stack/Stack';
import Text from '../../../../shared/ui/Text/Text';
import { data, dataProjects, websiteDescription } from '../../lib/data';
import Button from '../../../../shared/ui/Button/Button';
import { Line } from '../../../../shared/ui/Line/Line';
import useModal from '../../../../shared/hooks/useModal';
import Modal from '../../../../shared/ui/Modal/Modal';
import useOverflowHidden from '../../../../shared/hooks/useOverflowHidden';
import ProjectsModal from '../ProjectsModal/ProjectsModal';

const Projects = () => {
    const { isOpen, changeOpen } = useModal();
    const [selectedProject, setSelectedProject] = useState(null);
    useOverflowHidden(isOpen);

    const projectClasses = [
        styles.project1,
        styles.project2,
        styles.project3,
        styles.project4,
    ];

    const handleOpenModal = (projectTitle) => {
        const projectData = websiteDescription.find((proj) => proj.title === projectTitle);
        setSelectedProject(projectData);
        changeOpen();
    };

    return (
        <section 
            id='projects' 
            className={styles.sectionProjects}
        >
            <Stack align='alignCenter'>
                <Text 
                    type='h2' 
                    size='xl' 
                    className={styles.projectsHeading}
                > 
                    {data.heading} 
                </Text>
                <Line className={styles.svgMobile} />
                <Line width={100} className={styles.svgDesktop} />
            </Stack>

            <Text 
                size='xs' 
                className={styles.projectsSubheading}
            > 
                {data.subheading} 
            </Text>
            <div className={styles.projectWrapper}>
                {dataProjects.map((project, index, array) => (
                    <Stack 
                        direction='column' 
                        key={index}
                        className={`${styles.project} ${projectClasses[index]}`}
                    >
                        <div className={styles.projectContent}>
                            <Stack 
                                justify='justifyBetween' 
                                align='alignCenter'
                            >
                                <Text >
                                    {project.type}
                                </Text>
                                {index === array.length - 1 ? (
                                <Button 
                                    text={project.btn} 
                                    className={styles.btn}
                                    disabled
                                />
                                ):(
                                <Button 
                                    text={project.btn} 
                                    className={styles.btn}
                                    onClick={() => handleOpenModal(project.title)}
                                />
                                )}
                                
                            </Stack>
                            <Text 
                                type='h2' 
                                size='lg' 
                                className={styles.projectCardTitle}
                            >
                                {project.title}
                            </Text>
                        </div>
                    </Stack>
                ))}
            </div>

            {isOpen && selectedProject && (
                <Modal changeOpen={changeOpen}>
                    <ProjectsModal changeOpen={changeOpen} project={selectedProject} />
                </Modal>
            )}
        </section>
    );
}

export default Projects;

