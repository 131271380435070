import React from 'react'
import Text from '../../shared/ui/Text/Text';
import styles from './NotFound.module.scss';
import Button from '../../shared/ui/Button/Button';
import { Arrow } from '../../shared/ui/Arrow/Arrow';
import { useNavigate } from 'react-router';



const NotFound = () => {
    const navigate = useNavigate();
    
    return (
    <>
        <header className={styles.headerNotFound}>
            <Text>
                <strong>
                AL Tech Labs
                </strong>
            </Text>
        </header>
        <main className={styles.mainNotFound}>
            <Text className={styles.number}>
                404
            </Text>
            <Text 
                type='h1' 
                size='lg'
                className={styles.heading}
            >
                Oops! The page you are looking for cannot be found.
            </Text>
            <Text className={styles.subheading}>
                It might have been removed, renamed, or did not exist in the first place.
            </Text>

            <Button onClick={() => navigate('/')}>
                Go to Homepage
                <Arrow />
            </Button>
        </main>
    </>
    )
}

export default NotFound;
