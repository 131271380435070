export const Line = ({ width=58, className, ...props}) => {
    return (
        <svg 
            width={width} 
            height="2" 
            viewBox="0 0 58 2" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            {...props}
        >
            <line x1="0.399902" y1="1" x2="57.5191" y2="1" stroke="#9D3BD0" strokeWidth="2"/>
        </svg>
    )
}
