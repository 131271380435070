import Text from "../../../../shared/ui/Text/Text";
import { Stack } from "../../../../shared/ui/Stack/Stack";
import styles from "./ServicesCard.module.scss";

export const ServicesCard = ({ icon, title, content }) => {
  return (
    <Stack direction="column" className={styles.card}>
      <img 
        src={icon} 
        alt="" 
        className={styles.icons} 
        width='auto'
        height='auto'
        loading="lazy"
        
      />
      <Text 
        type="h2" 
        size="sm"
        className={styles.title} 
      >
        {title}
      </Text>
      <Text className={styles.content}>
        {content}
      </Text>
    </Stack>
  );
};
