import React, { useState } from 'react';
import styles from './NavbarMobile.module.scss';
import { HashLink } from 'react-router-hash-link';
import useOverflowHidden from '../../../../shared/hooks/useOverflowHidden';
import { dataMobile, dataCta } from '../../lib/data';
import Button from '../../../../shared/ui/Button/Button';
import { Arrow } from '../../../../shared/ui/Arrow/Arrow';
import { Stack } from '../../../../shared/ui/Stack/Stack';
import Text from '../../../../shared/ui/Text/Text';

const NavbarMobile = () => {
    const [isOpen, setIsOpen] = useState(false);

    useOverflowHidden(isOpen);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <nav 
            className={styles.navbarMobile} 
            aria-label="Main Navigation"
        >
            <button
                className={`${styles.hamburger} ${isOpen ? styles.open : ''}`}
                onClick={toggleMenu}
                type="button"
                aria-label="Open Hamburger Menu"
                aria-expanded={isOpen}
            >
                <div className={styles.lineTop}></div>
                <div className={styles.lineMiddle}></div>
                <div className={styles.lineBottom}></div>
            </button>
            <ul className={`${styles.menu} ${isOpen ? styles.menuOpen : ''}`}>
                {Object.values(dataMobile).map(({ text, path }, index, array) => (
                    <li key={path}>
                        {index === array.length - 1 ? (
                            <Stack direction="column">
                                <Text className={styles.ctaText}>
                                    {dataCta.ctaText}
                                    </Text>
                                    <HashLink 
                                        smooth to={`#${path}`}
                                        onClick={closeMenu}
                                    >
                                    <Button className={styles.ctaBtn}>
                                        {text}
                                        <Arrow />
                                    </Button>
                                </HashLink>
                            </Stack>
                        ) : (
                            <HashLink 
                                smooth to={`#${path}`}
                                onClick={closeMenu}
                            >
                                <Button
                                    navigation
                                    className={styles.navigationBtn}
                                    text={text}
                                />
                            </HashLink>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default NavbarMobile;
