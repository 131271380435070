import React, { useEffect, useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import styles from './ContactForm.module.scss';
import InputField from '../../shared/ui/InputField/InputField';
import { Stack } from '../../shared/ui/Stack/Stack';
import Button from '../../shared/ui/Button/Button';
import { ListBox } from '../../shared/ui/ListBox/ListBox';

function ContactForm({ changeOpen }) {

  const [state, handleSubmit] = useForm("myzgzyql");
  const [showForm, setShowForm] = useState(true);
  const [selectedService, setSelectedService] = useState('WEB DEVELOPMENT');
  console.log(selectedService);

  const options = [
    'WEB DEVELOPMENT',
    'MOBILE RESPONSIVENESS',
    'E-COMMERCE DEVELOPMENT',
    'SEARCH ENGINE OPTIMIZATION',
    'WEB DESIGN',
    'MOBILE APP DEVELOPMENT',
  ];

  useEffect(() => {
    if (state.succeeded) {
      setShowForm(false);
      const timer = setTimeout(() => {
        changeOpen(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [state.succeeded, changeOpen]);

  if (!showForm) {
    return <p>Thank you for your request! We will get back to you soon.</p>;
  }

  const handleCloseModal = () => {
    changeOpen(false);
  };

  return (
    <Stack justify='justifyCenter'>
      <Button
        navigation
        className={styles.closeBtn}
        onClick={handleCloseModal}
        aria-label="Close Modal Window"
      >
        <svg
          className={styles.closeBtnSvg}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.08095 7.26418L14.5335 0.71248L13.8318 0L7.37925 6.5517L1.16911 0.246094L0.467412 0.958573L6.67756 7.26418L0 14.0444L0.701693 14.7569L7.37925 7.97666L14.2992 15.003L15.0009 14.2905L8.08095 7.26418Z"
            fill="black"
          />
        </svg>
      </Button>
      <form onSubmit={handleSubmit} className={styles.form}>
        <InputField
          id="name"
          label="Your Name"
          type="text"
          name="name"
          required
          state={state}
        />
        <InputField
          id="email"
          label="Email Address"
          type="email"
          name="email"
          required
          state={state}
        />
        <label htmlFor="service">Select Service</label>
        <ListBox
          id="service"
          name="service"
          required
          options={options}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
        />
        <input type="hidden" name="service" value={selectedService} />

        <ValidationError
          prefix="Service"
          field="service"
          errors={state.errors}
        />
        <InputField
          id="budget"
          label="Project Budget"
          type="text"
          name="budget"
          required
          state={state}
        />
        <InputField
          id="timeline"
          label="Project Timeline"
          type="text"
          name="timeline"
          required
          state={state}
        />
        <label htmlFor="message">Give a little description about the project</label>
        <textarea
          id="message"
          name="message"
          className={styles.inputField}
          required
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        <Button
          type="submit"
          disabled={state.submitting}
          className={styles.btn}
        >
          Submit
          <svg
            width="18"
            height="18"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M36.3542 1.64582C35.9601 1.2545 35.4657 0.979403 34.9255 0.850764C34.3852 0.722125 33.8199 0.744922 33.2917 0.91665L2.81256 11.0833C2.24729 11.2624 1.74778 11.605 1.37711 12.0679C1.00645 12.5307 0.78126 13.093 0.729988 13.6838C0.678715 14.2745 0.803659 14.8672 1.08904 15.387C1.37442 15.9068 1.80743 16.3303 2.33339 16.6042L14.9792 22.8542L21.2292 35.5417C21.4804 36.0383 21.8648 36.4552 22.3395 36.7459C22.8141 37.0365 23.3602 37.1894 23.9167 37.1875H24.1251C24.7211 37.1436 25.2901 36.9214 25.7583 36.5499C26.2264 36.1784 26.572 35.6746 26.7501 35.1041L37.0626 4.70832C37.2467 4.18317 37.2779 3.61653 37.1525 3.07435C37.0271 2.53217 36.7502 2.03677 36.3542 1.64582ZM4.10422 13.9583L30.7084 5.08332L15.9376 19.8541L4.10422 13.9583ZM24.0626 33.8958L18.1459 22.0625L32.9167 7.29165L24.0626 33.8958Z" fill="white" />
          </svg>
        </Button>
      </form>
    </Stack>
  );
}

export default ContactForm;
