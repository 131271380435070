import React from 'react';
import Navbar from '../../features/Navbar/ui/Navbar/Navbar';
import Text from '../../shared/ui/Text/Text';
import styles from './Header.module.scss';
import NavbarMobile from '../../features/Navbar/ui/NavbarMobile/NavbarMobile';

const Header = () => {
  return (
    <header className={styles.header}>
        <Text>
            <strong>
                AL Tech Labs
            </strong>
        </Text>
        <NavbarMobile />
        <Navbar />
    </header>
  )
}

export default Header