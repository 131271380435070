export const data = {
    heading: 'Why choose Us',
    subheading: "Our vision and mission",
}

export const dataWhy = [
    {
        title: 'Expertise and Innovation',
        content:'Our team brings together a wealth of expertise in web development, staying at the forefront of technological advancements to deliver cutting-edge solutions.'
    },
    {
        title: 'Customized Solutions',
        content: "We understand that every project is different. Whether you're a startup or a seasoned enterprise, we tailor our services to align perfectly with your vision and goals."
    },
    {
        title: "Collaborative Approach",
        content: "We believe in open communication and collaboration throughout the development process. Your input and feedback are integral to creating solutions that exceed expectations."
    },
    {
        title: "Quality and Reliability",
        content: "We are committed to delivering high-quality work on time and within budget. Our rigorous testing ensures that your website or application performs flawlessly across all platforms."
    },
    {
        title: "Customer Satisfaction",
        content: "Your satisfaction is our priority. We are dedicated to building long-term relationships with our clients, providing ongoing support and maintenance to ensure continued success."
    }
]
