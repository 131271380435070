import { useEffect, useState } from 'react';
import { data, dataService } from "../../lib/data";
import Text from "../../../../shared/ui/Text/Text";
import { Stack } from "../../../../shared/ui/Stack/Stack";
import { ServicesCard } from "../ServicesCard/ServicesCard";
import SwiperContainer from '../../../../shared/ui/SwiperContainer/SwiperContainer';
import styles from "./Services.module.scss";
import { Line } from "../../../../shared/ui/Line/Line";

export const Services = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const cards = dataService.map((item, index) => (
    <ServicesCard
      key={index}
      icon={item.icon}
      title={item.title}
      content={item.content}
    />
  ));

  return (
    <section 
      id="services" 
      className={styles.section}
    >
      <Stack direction="column" className={styles.container}>
        <Stack align="alignCenter">
          <Text 
            type="h2" 
            size="xl" 
            className={styles.servicesTitle}
          >
            {data.title}
          </Text>
          <Line className={styles.svgMobile} />
          <Line width={100} className={styles.svgDesktop} />
        </Stack>

        <Text className={styles.servicesSubtitle}>
          {data.subtitle}
        </Text>
        <Stack 
          justify="justifyBetween" 
          gap='20' 
          className={styles.cards}
        >
          {isMobile ? (
            <SwiperContainer>{cards}</SwiperContainer>
          ) : (
            cards
          )}
        </Stack>
      </Stack>
    </section>
  );
};
