export const data = {
    about: {
        path: 'about',
        text: 'About Us'
    },
    services: {
        path: 'services',
        text: 'Services'
    },
    projects: {
        path: 'projects',
        text: 'Projects'
    },
}