import React from 'react'
import { Stack } from '../../../shared/ui/Stack/Stack';
import { data } from '../lib/data';
import Text from '../../../shared/ui/Text/Text';
import styles from '../ui/Hero.module.scss'
import Button from '../../../shared/ui/Button/Button';
import mainImage from '../../../shared/assets/images/main-image.svg';
import { Arrow } from '../../../shared/ui/Arrow/Arrow';
import useOverflowHidden from '../../../shared/hooks/useOverflowHidden';
import useModal from '../../../shared/hooks/useModal';
import Modal from '../../../shared/ui/Modal/Modal';
import ContactForm from '../../ContactForm/ContactForm'

const Hero = () => {
    const { isOpen, changeOpen } = useModal();
    useOverflowHidden(isOpen);

    const handleOpenModal = () => {
        changeOpen();
    };

    return (
    <section className={styles.heroSection}>
            <Stack 
                direction='column' 
                className={styles.textContainer}
            >
                <Text 
                    type='h1' 
                    size='xxl'
                >
                    {data.title}
                </Text>
                <Text className={styles.subtitle}>
                    {data.subtitle}
                </Text>
                <div>
                    <Button 
                        className={styles.buttonDesktop}
                        onClick={handleOpenModal}
                    >
                        {data.btn}
                        <Arrow />
                    </Button>
                </div>
            </Stack>
            <img 
                src={mainImage} 
                alt='AL Tech Labs Website and Apps development' 
                className={styles.mainImage}
            />
            <div>
                <Button 
                    className={styles.buttonMobile} 
                    onClick={handleOpenModal}
                >
                    {data.btn}
                    <Arrow />
                </Button>
            </div>

            {isOpen && (
                <Modal changeOpen={changeOpen}>
                    <ContactForm changeOpen={changeOpen} />
                </Modal>
            )}
    </section>
    )
}

export default Hero;