import React from 'react';
import Text from '../../../../shared/ui/Text/Text';
import styles from './ProjectsModal.module.scss';
import { Stack } from '../../../../shared/ui/Stack/Stack';
import Button from '../../../../shared/ui/Button/Button';
import { Arrow } from '../../../../shared/ui/Arrow/Arrow';

const ProjectsModal = ({ changeOpen, project }) => {

    const handleCloseModal = () => {
            changeOpen(false);
    };

    return (
        <Stack className={styles.modalContainer}>
            <Button
                navigation
                className={styles.closeBtn} 
                onClick={handleCloseModal}
                aria-label="Close Modal Window"
            >
                <svg 
                    className={styles.closeBtnSvg} 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="15" 
                    height="15" 
                    viewBox="0 0 15 15" 
                    fill="none"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.08095 7.26418L14.5335 0.71248L13.8318 0L7.37925 6.5517L1.16911 0.246094L0.467412 0.958573L6.67756 7.26418L0 14.0444L0.701693 14.7569L7.37925 7.97666L14.2992 15.003L15.0009 14.2905L8.08095 7.26418Z"
                        fill="black"
                    />
                </svg>
            </Button>
            <div>
                <Text 
                    type='h2' 
                    size='xl'
                    className={styles.mainHeading}
                >
                    <strong>
                        {project.title}
                    </strong>
                </Text>
                <Text 
                    type='h3' 
                    size='lg'
                >
                    <strong>
                        {project.overviewTitle}
                    </strong>
                </Text>
                <Text className={styles.text}>{project.overview}</Text>

                <Stack className={styles.container}>
                    <Stack direction='column' className={styles.bestPracticeContainer}>
                        <Text 
                            type='h3' 
                            size='lg'
                            className={styles.heading}
                        >
                            <strong>
                                {project.bestPractiseTitle}
                            </strong>
                        </Text>
                        <ul className={styles.projectList}>
                            {project.bestPractiseBullits.map((bullet, index) => (
                                <Text 
                                    type='li' 
                                    key={index}
                                    className={styles.text}
                                >
                                    {bullet}
                                </Text>
                            ))}
                        </ul>
                            <a 
                            href={project.link} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className={styles.projectLink}
                        >
                            <Button>
                                Live Link
                                <Arrow />
                            </Button>
                        </a>
                    </Stack>
                    <Stack direction='column' className={styles.imageContainer}>
                        {project.images?.map((image, imageIndex) => (
                            <picture key={imageIndex} className={styles[`image${imageIndex + 1}`]} >
                                <source srcSet={image.srcWebp} type="image/webp" />
                                <img 
                                    src={image.src} 
                                    alt={image.alt} 
                                    width='auto'
                                    height='auto'
                                    loading="lazy"
                                />
                            </picture>
                        ))}
                    </Stack>
                </Stack>
            </div>
        </Stack>
    )
}

export default ProjectsModal;
