import React, { Suspense } from 'react';
import Hero from '../../entities/Hero/ui/Hero';
import Header from '../../widgets/Header/Header';
import { Services } from '../../entities/Services/ui/Services/Services';
import Projects from '../../entities/Projects/ui/Projects/Projects';
import LetsGetInTouch from '../../entities/LetsGetInTouch/LetsGetInTouch';
import Footer from '../../features/Footer/ui/Footer';
import Accordion from '../../entities/WhyUs/ui/Accordion/Accordion';
import Loader from '../../shared/ui/Loader/Loader';

const About = React.lazy(() => import('../../entities/About/ui/AboutUs'));


const MainPage = () => {
    return (
        <>
            <Header />
            <Hero />
            <Suspense fallback={<Loader />}>
                <About />
            </Suspense>
            <Services/>
            <Accordion />
            <Projects />
            <LetsGetInTouch />
            <Footer />
        </>
    )
}

export default MainPage;