import React from 'react';
import styles from './Button.module.scss';
import { getStyle } from '../../helper/getStyle';

const Button = ({ text, children, onClick, active, disabled, navigation, className }) => {
  const mode = {
    [styles.active]: active,
    [styles.disabled]: disabled,
    [styles.navigation]: navigation,
  };
  const additional = [
    className
  ];
  return (
    <button
      className={getStyle(styles.button, mode, additional, [])}
      onClick={onClick}
      disabled={disabled} 
    >
        {text || children}
    </button>
  )
}

export default Button;