import styles from './Loader.module.scss';

const Loader = () => {
    return(
        <div className={styles.containerLoader}>
            <div className={styles.ldsRoller}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
};
export default Loader;