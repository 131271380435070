import imageMobileInteriorDesigner from '../../../shared/assets/images/mobile-interior-designer.png';
import imageMobileInteriorDesignerWebp from '../../../shared/assets/images/webp/mobile-interior-designer.webp';
import imageDesktopFirstInteriorDesigner from '../../../shared/assets/images/desktop-first-interior-designer.png';
import imageDesktopFirstInteriorDesignerWebp from '../../../shared/assets/images/webp/desktop-first-interior-designer.webp';
import imageDesktopSecondInteriorDesigner from '../../../shared/assets/images/desktop-second-interior-designer.png';
import imageDesktopSecondInteriorDesignerWebp from '../../../shared/assets/images/webp/desktop-second-interior-designer.webp';

import imageMobileMortgageAdviser from '../../../shared/assets/images/mobile-mortgage-adviser.png';
import imageMobileMortgageAdviserWebp from '../../../shared/assets/images/webp/mobile-mortgage-adviser.webp';
import imageDesktopFirstMortgageAdviser from '../../../shared/assets/images/desktop-first-mortgage-adviser.png';
import imageDesktopFirstMortgageAdviserWebp from '../../../shared/assets/images/webp/desktop-first-mortgage-adviser.webp';
import imageDesktopSecondMortgageAdviser from '../../../shared/assets/images/desktop-second-mortgage-adviser.png';
import imageDesktopSecondMortgageAdviserWebp from '../../../shared/assets/images/webp/desktop-second-mortgage-adviser.webp';

import imageMobileSavannahWorld from '../../../shared/assets/images/mobile-savannah-world.png';
import imageMobileSavannahWorldWebp from '../../../shared/assets/images/webp/mobile-savannah-world.webp';
import imageDesktopFirstSavannahWorld from '../../../shared/assets/images/desktop-first-savannah-world.png';
import imageDesktopFirstSavannahWorldWebp from '../../../shared/assets/images/webp/desktop-first-savannah-world.webp';
import imageDesktopSecondSavannahWorld from '../../../shared/assets/images/desktop-second-savannah-world.png';
import imageDesktopSecondSavannahWorldWebp from '../../../shared/assets/images/webp/desktop-second-savannah-world.webp';

export const data ={
    heading: 'Our latest Work',
    subheading: 'Discover our latest projects',
}

export const dataProjects = [
    {
        type: 'Website',
        title: 'Savannah world',
        btn: 'More info'
    },
    {
        type: 'Website',
        title: 'Interior Designer',
        btn: 'More info'
    },
    {
        type: 'Website',
        title: 'Mortgage adviser',
        btn: 'More info'
    },
    {
        type: 'Website',
        title: 'Numerology and Tarot',
        btn: 'More info'
    },
]
export const websiteDescription = [
    {
        title: 'Savannah world',
        overviewTitle: "Overview",
        overview: "Developed a FullStack application for a Savannah cat breeding cattery with a comprehensive content management system. This system allows the site owner to independently manage site data, including adding, editing, and deleting kitten cards in the catalog. The application features advanced filtering, sorting, and search mechanisms to enhance user navigation. It is built with a custom UI component library, ensuring consistent and reusable design elements throughout. Utilizing the SASS preprocessor, the application ensures efficient and maintainable styling. Semantic HTML structure guarantees improved accessibility and SEO. The application delivers an exceptional user experience, providing a robust and intuitive platform for managing and showcasing Savannah cats.",
        bestPractiseTitle: 'Best Practices',
        bestPractiseBullits:[
            "Semantic HTML: Used semantic HTML elements to improve the site's SEO and accessibility, making the content more understandable for search engines and assistive technologies.",
            "Adaptive Design: Ensured the application is fully adaptive, providing an optimal user experience across all devices and screen sizes.",
            "Custom UI Library: Built a custom UI component library to ensure consistent and reusable design elements throughout the application, promoting a cohesive look and feel."
        ],
        images: [
            { src: imageMobileSavannahWorld, srcWebp: imageMobileSavannahWorldWebp, alt: 'Mobile Savannah world', desktop: 'image1' },
            { src: imageDesktopFirstSavannahWorld, srcWebp: imageDesktopFirstSavannahWorldWebp, alt: 'Desktop First Savannah world', desktop: 'image2' },
            { src: imageDesktopSecondSavannahWorld, srcWebp: imageDesktopSecondSavannahWorldWebp, alt: 'Desktop Second Savannah world', desktop: 'image3' },
        ],
        link: 'https://ts-savannah-world.netlify.app/'
    },
    {
        title: 'Interior Designer',
        overviewTitle: "Overview",
        overview: "Developed a sophisticated portfolio website for an interior designer, utilizing cutting-edge web technologies and best practices to deliver an exceptional user experience. The site is built with a mobile-first approach, ensuring optimal performance and usability across all devices, and features a custom UI component library for consistent and reusable design elements.",
        bestPractiseTitle: 'Best Practices',
        bestPractiseBullits:[
            "Semantic HTML: Used semantic HTML elements to improve the site's SEO and accessibility, making the content more understandable for search engines and assistive technologies.",
            "SEO: Implemented best practices for search engine optimization to enhance the site's visibility and ranking on search engines.",
            "Accessibility: Focused on accessibility to ensure the site is usable for people with disabilities, adhering to WCAG guidelines.",
            "Progressive Web App (PWA) Features: Incorporated Progressive Web App features to allow users to install the application on their devices, enhancing engagement and providing offline access to essential content."
        ],
        images: [
            { src: imageMobileInteriorDesigner, srcWebp: imageMobileInteriorDesignerWebp, alt: 'Mobile Interior Designer', desktop: 'image1' },
            { src: imageDesktopFirstInteriorDesigner, srcWebp: imageDesktopFirstInteriorDesignerWebp, alt: 'Desktop First Interior Designer', desktop: 'image2' },
            { src: imageDesktopSecondInteriorDesigner, srcWebp: imageDesktopSecondInteriorDesignerWebp, alt: 'Desktop Second Interior Designer', desktop: 'image3' },
        ],
        link: 'https://olesya-martin.netlify.app/'
    },
    {
        title: 'Mortgage adviser',
        overviewTitle: "Overview",
        overview: "Developed an application for a mortgage adviser with a content management system. This system allows the site owner to independently update site data such as statistics, mortgage rates, and user reviews, including uploading and deleting images. The application integrates seamlessly with the Calendly service for convenient appointment booking. It is built with a custom UI component library, ensuring consistent and reusable design elements throughout. The application guarantees adaptivity across all devices, delivering an exceptional user experience.",
        bestPractiseTitle: 'Best Practices',
        bestPractiseBullits:[
            "Semantic HTML: Used semantic HTML elements to improve the site's SEO and accessibility, making the content more understandable for search engines and assistive technologies.",
            "Responsive Design: Ensured the application is fully responsive, providing an optimal user experience across all devices and screen sizes.",
            "Testimonials Carousel: Integrated a dynamic carousel to display client testimonials, enhancing user engagement and showcasing positive feedback.",
            "Mortgage Calculator: Implemented an interactive mortgage calculator, allowing users to estimate their mortgage payments easily and accurately."
        ],
        images: [
            { src: imageMobileMortgageAdviser, srcWebp: imageMobileMortgageAdviserWebp, alt: 'Mobile Mortgage Adviser', desktop: 'image1' },
            { src: imageDesktopFirstMortgageAdviser, srcWebp: imageDesktopFirstMortgageAdviserWebp, alt: 'Desktop First Mortgage Adviser', desktop: 'image2' },
            { src: imageDesktopSecondMortgageAdviser, srcWebp: imageDesktopSecondMortgageAdviserWebp, alt: 'Desktop Second Mortgage Adviser', desktop: 'image3' },
        ],
        link: 'https://mortage-cz.netlify.app/'
    },
    
]