import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import styles from './SwiperContainer.module.scss';

const SwiperContainer = ({ className, children }) => {
  return (
    <>
    <Swiper
      spaceBetween={20}
      slidesPerView={1}
      pagination={{
        clickable: true,
  
      }}
      modules={[Pagination]}
      breakpoints={{
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
    >
      {children.map((child, index) => (
        <SwiperSlide key={index} className={className}>{child}</SwiperSlide>
      ))}
    </Swiper>
      <div className={styles['swiper-pagination']}></div>
      </>
  );
};

export default SwiperContainer;
