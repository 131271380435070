import React from 'react'
import Text from '../../shared/ui/Text/Text';
import { Stack } from '../../shared/ui/Stack/Stack';
import styles from './LetsGetInTouch.module.scss'
import Button from '../../shared/ui/Button/Button';
import { Arrow } from '../../shared/ui/Arrow/Arrow';
import useOverflowHidden from '../../shared/hooks/useOverflowHidden';
import ContactForm from '../ContactForm/ContactForm';
import Modal from '../../shared/ui/Modal/Modal';
import useModal from '../../shared/hooks/useModal';

const LetsGetInTouch = () => {

    const { isOpen, changeOpen } = useModal();
    useOverflowHidden(isOpen);

    const handleOpenModal = () => {
        changeOpen();
    };

    return (
        <section id='contacts' className={styles.sectionContacts}>
            <Stack justify='justifyEnd' className={styles.desktop}>
                <Stack direction='column'>
                    <a
                        href='https://www.linkedin.com/company/al-tech-labs-ltd/'
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                    >
                        <svg
                            className={styles.svgLink}
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M39.5833 6.25C40.6884 6.25 41.7482 6.68899 42.5296 7.47039C43.311 8.25179 43.75 9.3116 43.75 10.4167V39.5833C43.75 40.6884 43.311 41.7482 42.5296 42.5296C41.7482 43.311 40.6884 43.75 39.5833 43.75H10.4167C9.3116 43.75 8.25179 43.311 7.47039 42.5296C6.68899 41.7482 6.25 40.6884 6.25 39.5833V10.4167C6.25 9.3116 6.68899 8.25179 7.47039 7.47039C8.25179 6.68899 9.3116 6.25 10.4167 6.25H39.5833ZM38.5417 38.5417V27.5C38.5417 25.6987 37.8261 23.9712 36.5524 22.6976C35.2787 21.4239 33.5513 20.7083 31.75 20.7083C29.9792 20.7083 27.9167 21.7917 26.9167 23.4167V21.1042H21.1042V38.5417H26.9167V28.2708C26.9167 26.6667 28.2083 25.3542 29.8125 25.3542C30.586 25.3542 31.3279 25.6615 31.8749 26.2084C32.4219 26.7554 32.7292 27.4973 32.7292 28.2708V38.5417H38.5417ZM14.3333 17.8333C15.2616 17.8333 16.1518 17.4646 16.8082 16.8082C17.4646 16.1518 17.8333 15.2616 17.8333 14.3333C17.8333 12.3958 16.2708 10.8125 14.3333 10.8125C13.3996 10.8125 12.504 11.1834 11.8437 11.8437C11.1834 12.504 10.8125 13.3996 10.8125 14.3333C10.8125 16.2708 12.3958 17.8333 14.3333 17.8333ZM17.2292 38.5417V21.1042H11.4583V38.5417H17.2292Z" fill="#9D3BD0" />
                        </svg>
                        Follow Us on LinkedIn
                    </a>
                    <a
                        href='mailto:service@altech.digital'
                        className={styles.link}
                    >
                        <svg
                            className={styles.svgLink}
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M45.8333 12.5002C45.8333 10.2085 43.9583 8.3335 41.6667 8.3335H8.33332C6.04166 8.3335 4.16666 10.2085 4.16666 12.5002V37.5002C4.16666 39.7918 6.04166 41.6668 8.33332 41.6668H41.6667C43.9583 41.6668 45.8333 39.7918 45.8333 37.5002V12.5002ZM41.6667 12.5002L25 22.9168L8.33332 12.5002H41.6667ZM41.6667 37.5002H8.33332V16.6668L25 27.0835L41.6667 16.6668V37.5002Z" fill="#9D3BD0" />
                        </svg>
                        service@altech.digital
                    </a>
                </Stack>
            </Stack>
            <Stack direction='column'>
                <Text size='xxl' className={styles.text1}>
                    LET'S
                </Text>
                <Stack
                    direction='column'
                    className={styles.container}
                >
                    <Text size='xxl' className={styles.text2}>
                        GET IN TOUCH
                    </Text>
                    <Button onClick={handleOpenModal}>
                        Get a quote
                        <Arrow />
                    </Button>
                </Stack>
            </Stack>

            <Stack
                direction='column'
                gap='10'
                className={styles.mobile}
            >
                <a
                    href='https://www.linkedin.com/company/al-tech-labs-ltd/'
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                >
                    <svg
                        className={styles.svgLink}
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M39.5833 6.25C40.6884 6.25 41.7482 6.68899 42.5296 7.47039C43.311 8.25179 43.75 9.3116 43.75 10.4167V39.5833C43.75 40.6884 43.311 41.7482 42.5296 42.5296C41.7482 43.311 40.6884 43.75 39.5833 43.75H10.4167C9.3116 43.75 8.25179 43.311 7.47039 42.5296C6.68899 41.7482 6.25 40.6884 6.25 39.5833V10.4167C6.25 9.3116 6.68899 8.25179 7.47039 7.47039C8.25179 6.68899 9.3116 6.25 10.4167 6.25H39.5833ZM38.5417 38.5417V27.5C38.5417 25.6987 37.8261 23.9712 36.5524 22.6976C35.2787 21.4239 33.5513 20.7083 31.75 20.7083C29.9792 20.7083 27.9167 21.7917 26.9167 23.4167V21.1042H21.1042V38.5417H26.9167V28.2708C26.9167 26.6667 28.2083 25.3542 29.8125 25.3542C30.586 25.3542 31.3279 25.6615 31.8749 26.2084C32.4219 26.7554 32.7292 27.4973 32.7292 28.2708V38.5417H38.5417ZM14.3333 17.8333C15.2616 17.8333 16.1518 17.4646 16.8082 16.8082C17.4646 16.1518 17.8333 15.2616 17.8333 14.3333C17.8333 12.3958 16.2708 10.8125 14.3333 10.8125C13.3996 10.8125 12.504 11.1834 11.8437 11.8437C11.1834 12.504 10.8125 13.3996 10.8125 14.3333C10.8125 16.2708 12.3958 17.8333 14.3333 17.8333ZM17.2292 38.5417V21.1042H11.4583V38.5417H17.2292Z" fill="#9D3BD0" />
                    </svg>
                    company/al-tech-labs-ltd/
                </a>
                <a
                    href='mailto:service@altech.digital'
                    className={styles.link}
                >
                    <svg
                        className={styles.svgLink}
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M45.8333 12.5002C45.8333 10.2085 43.9583 8.3335 41.6667 8.3335H8.33332C6.04166 8.3335 4.16666 10.2085 4.16666 12.5002V37.5002C4.16666 39.7918 6.04166 41.6668 8.33332 41.6668H41.6667C43.9583 41.6668 45.8333 39.7918 45.8333 37.5002V12.5002ZM41.6667 12.5002L25 22.9168L8.33332 12.5002H41.6667ZM41.6667 37.5002H8.33332V16.6668L25 27.0835L41.6667 16.6668V37.5002Z" fill="#9D3BD0" />
                    </svg>
                    service@altech.digital
                </a>
            </Stack>

            {isOpen && (
                <Modal changeOpen={changeOpen}>
                    <ContactForm changeOpen={changeOpen} />
                </Modal>
            )}
        </section>
    )
}

export default LetsGetInTouch;
