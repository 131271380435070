import { data, dataWhy } from '../../lib/data';
import styles from "./Accordion.module.scss";
import { AccordionItem } from '../AccordionItem/AccordionItem';
import Text from '../../../../shared/ui/Text/Text';
import { Stack } from '../../../../shared/ui/Stack/Stack';
import { Line } from '../../../../shared/ui/Line/Line';

const Accordion = () => {
  return (
    <section className={styles.accordion}>
      <Stack className={styles.headingContainer}>
        <Stack direction='column'>
          <Stack align="alignCenter">
              <Text 
                type="h2" 
                size="xl" 
                className={styles.heading}
              >
                {data.heading}
              </Text>
              <Line className={styles.svgMobile} />
              <Line width={100} className={styles.svgDesktop} />
            </Stack>
            <Text className={styles.subheading}> 
                {data.subheading} 
            </Text>
        </Stack>
      </Stack>

      {dataWhy.map((item, index) => (
        <AccordionItem
          key={item.title} 
          title={item.title} 
          content={item.content} 
          defaultOpen={index === 0}
        />
      ))}
    </section>
  );
};

export default Accordion;
