export const data = {
    about: {
        path: 'about',
        text: 'About Us'
    },
    services: {
        path: 'services',
        text: 'Services'
    },
    projects: {
        path: 'projects',
        text: 'Projects'
    },
    contacts: {
        path: 'contacts',
        text: 'Contact Us'
    },
}
export const dataMobile = {
    about: {
        path: 'about',
        text: 'About Us'
    },
    services: {
        path: 'services',
        text: 'Services'
    },
    projects: {
        path: 'projects',
        text: 'Projects'
    },
    contacts: {
        path: 'contacts',
        text: 'Get a quote'
    },
}
export const dataCta = {
    ctaText: 'Have a project in mind?' ,
}