import React from 'react'
import Button from '../../../../shared/ui/Button/Button';
import { data } from '../../lib/data';
import { HashLink } from 'react-router-hash-link'; 
import styles from '../Navbar/Navbar.module.scss'
import { Arrow } from '../../../../shared/ui/Arrow/Arrow';


const Navbar = () => {
    return (
    <nav aria-label="Main navigation">
        <ul className={styles.navigation}>
            {Object.values(data).map(({ text, path }, index, array) => (
                <li key={path}>
                    <HashLink smooth to={`#${path}`} >
                        {index === array.length - 1 ? (
                                <Button>
                                    {text}
                                    <Arrow />
                                </Button>
                            ) : (
                                <Button 
                                    navigation
                                    text={text}
                                    className={styles.btn}
                                />
                            )}
                    </HashLink>
                </li>
            ))}
        </ul>
    </nav>
    )
}

export default Navbar;