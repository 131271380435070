export const Arrow = ({ width=22, height=16, ...props }) => {
    return (
        <svg 
            width={width}
            height={height} 
            viewBox="0 0 22 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            {...props} 
        >
            <path d="M20.8986 8.60457C21.2892 8.21404 21.2892 7.58088 20.8986 7.19035L14.5347 0.826393C14.1441 0.435869 13.511 0.435869 13.1205 0.826393C12.7299 1.21692 12.7299 1.85008 13.1205 2.24061L18.7773 7.89746L13.1205 13.5543C12.7299 13.9448 12.7299 14.578 13.1205 14.9685C13.511 15.3591 14.1441 15.3591 14.5347 14.9685L20.8986 8.60457ZM0.191528 8.89746L20.1915 8.89746V6.89746L0.191528 6.89746L0.191528 8.89746Z" fill="white"/>
        </svg>
    )
}