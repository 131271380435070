import React from 'react';
import styles from './Footer.module.scss';
import { Stack } from '../../../shared/ui/Stack/Stack';
import Text from '../../../shared/ui/Text/Text';
import { HashLink } from 'react-router-hash-link';
import Button from '../../../shared/ui/Button/Button';
import { data } from '../lib/data'


const Footer = () => {
    return (
    <footer className={styles.footer}>
        <Stack justify='justifyCenter'>
            <svg 
                className={styles.svg}
                height="4" 
                viewBox="0 0 1194 4" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <line y1="1.62207" x2="1200" y2="1.62207" stroke="#9D3BD0" strokeWidth="3"/>
            </svg>
        </Stack>
        
        <Stack direction='column' align="alignCenter" className={styles.footerContainer}>
        <Text className={styles.desktop}>
            <strong>
                AL Tech Labs
            </strong>
        </Text>

        <ul className={styles.navigation}>
            {Object.values(data).map(({ text, path }) => (
                <li key={path}>
                    <HashLink smooth to={`#${path}`} >
                        <Button 
                            navigation
                            text={text}
                            className={styles.btn}
                        />
                    </HashLink>
                </li>
            ))}
        </ul>

        <Text>
            © AL Tech Labs 2024
        </Text>
        </Stack>

        <Stack justify='justifyCenter'>
            <svg 
                className={styles.svg}
                height="4" 
                viewBox="0 0 600 4" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <line y1="1.62207" x2="600" y2="1.62207" stroke="#9D3BD0" strokeWidth="3"/>
            </svg>
        </Stack>

    </footer>
    )
}

export default Footer;
